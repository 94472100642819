import DataService from "./DataService";

class MailLogDataService extends DataService {
  constructor() {
    super({
      slug: "mail_logs",
      modelConfig: {
        titleProperty: "reference",
        name: 'MailLog',
        fields: {
          id: {
            edit: false,
            grid: false,
            default: null
          },
          reference: {
            label: "Reference",
            type: "text",
            edit: {
              disabled: true
            }
          },
          content: {
            label: "Content",
            type: "textarea",
            grid: false,
            edit: {
              disabled: true
            }
          },
          created_at: {
            label: "Created",
            type: "datetime-local",
            edit: {
              disabled: true
            }
          }
        }
      }
    });
  }
}

export default new MailLogDataService();

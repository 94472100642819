<script>
import SPFGrid from '@/components/List/SPFGrid.vue';
import MailLogDataService from "@/services/MailLogDataService";

export default {
  name: 'MailLogList',
  extends: SPFGrid,
  props: {
    apiSlug: { default: 'mail_logs' },
    vueSlug: { default: 'mail_log' }
  },
  data() {
    return {
      dataService: MailLogDataService,
      fieldConfig: MailLogDataService.getGridConfig(),
    }
  }
}
</script>

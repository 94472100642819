<template>
  <div>
    <InvoiceForm
        @mainRecordLoaded="onMainRecordLoaded"
        :buttons="additionalFormButtons"
    >
      <template #before_buttons v-if="mainRecord && mainRecord.is_fixed">
        <div class="alert alert-warning" role="alert">
          This invoice has been fixed already, so no changes are possible anymore!
        </div>
      </template>

    </InvoiceForm>
    <BillableList
        v-if="mainRecord.id"
        :gridActions="getGridActions()"
        :hiddenColumns="getHiddenColumns()"
        :fixedFilters="getGridFixedFilters()"
        :batchActions="getBatchActions()"
        :renderKey="targetGridRenderKey"
        @submitBatchAction="reloadSourceGrid()"
    />
    <BillableList
        v-if="mainRecord.id && ! mainRecord.is_fixed"
        :gridActions="getGridActions()"
        :hiddenColumns="getHiddenColumns()"
        :fixedFilters="getGridFixedFilters(true)"
        :batchActions="getBatchActions(true)"
        :renderKey="sourceGridRenderKey"
        @submitBatchAction="reloadTargetGrid()"
    />
    <MailLogList
        v-if="mainRecord.email_sent_datetime"
        :batchActions="false"
        :gridActions="false"
        :fixedFilters="{reference: 'Invoice|' + $route.params.id}"
    ></MailLogList>
  </div>
</template>

<script>
import InvoiceForm from "@/components/InvoiceForm";
import BillableList from "@/components/BillableList";
import InvoiceDataService from "@/services/InvoiceDataService";
import BillableDataService from "@/services/BillableDataService";
import handleRequestError from "@/mixins/handleRequestError";
import MailLogList from "@/components/MailLogList";

export default {
  name: "InvoiceView",
  components: {MailLogList, BillableList, InvoiceForm },
  mixins: [ handleRequestError ],
  data() {
    const that = this;
    return {
      mainRecord: InvoiceDataService.getEntityDefinition(),
      sourceGridRenderKey: 0,
      targetGridRenderKey: 0,
      additionalFormButtons: {
        fixate: {
          text: "Fixate Invoice",
          class: "success",
          click: function () {
            InvoiceDataService.fixate(that.mainRecord.id).then((response) => {
              that.mainRecord = response.data.data.invoice;
              //console.warn(that.mainRecord);

              // TODO: the message won't be shown, all changes of the record should change the grids and disabled state of the fields

              that.$store.state.messages.push({
                title: "Success",
                message: "The Invoice has been fixated successfully!",
                type: "success"
              });
              that.$router.go(0);
            }).catch((e) => {
              that.handleRequestError(e)
            });
          }
        },
        show_pdf: {
          text: "Download PDF",
          class: "success",
          icon: ['fa', 'file-pdf'],
          click: function () {
            InvoiceDataService.showPdf(that.mainRecord).catch((e) => {
              that.handleRequestError(e);
            });
          }
        },
        show_spreadsheet: {
          text: "Download Spreadsheet",
          class: "success",
          icon: ['fa', 'file-excel'],
          click: function () {
            InvoiceDataService.showSpreadsheet(that.mainRecord).catch((e) => {
              that.handleRequestError(e);
            });
          }
        },
        email: {
          text: "Send E-Mail",
          class: "success",
          click: function () {
            that.$store.state.dialogs.push({
              message: (
                  that.mainRecord.email_sent_datetime ?
                      "The Email has been sent already, do you want it send again?" :
                      "Do you really want to send the e-mail?"
              ),
              onAcceptHandler: function (modal) {
                that.sendEmail();
                modal.hide();
              }
            });
          }
        }
      }
    }
  },
  props: {
    gridActions: [
      {
        route: "/new-invoice",
        icon: "plus-circle"
      }
    ],
  },

  methods: {
    sendEmail() {
      InvoiceDataService.sendMail(this.mainRecord.id).then(() => {
        this.$store.state.messages.push({
          title: "Success",
          message: "The e-mail has been sent successfully!",
          type: "success"
        });
      }).catch((e) => {
        this.handleRequestError(e);
      });
    },
    getGridActions() {
      return false;
    },
    reloadTargetGrid() {
      this.targetGridRenderKey++;
    },
    reloadSourceGrid() {
      this.sourceGridRenderKey++;
    },
    getBatchActions(forAddGrip) {
      const $comp = this;

      // do not show any batch actions if invoice is fixed
      if (this.mainRecord.is_fixed) {
        return false;
      }

      if (forAddGrip) {
        return [{
          fixed: true,
          name: "Add Billables to the Invoice",
          id: "add_records",
          method: function(recordIds) {
            return BillableDataService.updateList(recordIds, 'invoice_id', $comp.mainRecord.id);
          },
          successMessage: "Billables has been successfully added to the invoice!",
          errorMessage: "Billables has not been added to the invoice!",
        }];
      } else {
        return [{
          fixed: true,
          name: "Remove Billables from the Invoice",
          id: "add_records",
          method: function(recordIds) {
            return BillableDataService.updateList(recordIds, 'invoice_id', null);
          },
          successMessage: "Billables has been successfully removed from the invoice!",
          errorMessage: "Billables has not been removed from the invoice!",
        }];
      }
    },

    getHiddenColumns() {
      const filters = this.getGridFixedFilters();
      let columns = [];
      for (const [p] of Object.entries(filters)) {
        columns.push(p);
      }
      return columns;
    },

    getGridFixedFilters(forAddGrip) {
      let obj = {
        customer_id: this.mainRecord.customer_id
      }

      if (! forAddGrip) {
        obj.invoice_id = this.$route.params.id;
      } else {
        obj.date_between = this.mainRecord.date_from + ',' +  this.mainRecord.date_to;
        obj.is_null = "invoice_id";
      }

      return obj;
    },

    onMainRecordLoaded(record) {
      this.mainRecord = record;
    }
  },
}
</script>

<style scoped>

</style>
